import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { initTrialVersion, isTrialUrl} from "../../trialUtils";

type ConnectionContextType = {
  profiseeUrl: string;
  saveProfiseeUrl?: (profiseeUrl: string) => void;
  isTrialExperiencedEnabled?: boolean;
};

const ConnectionContext = createContext<ConnectionContextType>({
  profiseeUrl: "",
  isTrialExperiencedEnabled: false,
});
ConnectionContext.displayName = "ConnectionContext";

export const ConnectionProvider: React.FC = ({ children }) => {
  const [profiseeUrl, setProfiseeUrl] = useState<string>("");
  const [isTrialExperiencedEnabled, setIsTrialExperiencedEnabled] =
    useState(false);

  const saveProfiseeUrl = useCallback((profiseeUrl) => {
    setProfiseeUrl(profiseeUrl);
    if (isTrialUrl(profiseeUrl)) {
      initTrialVersion();
      setIsTrialExperiencedEnabled(true);
    }
  }, []);

  const value = useMemo(() => {
    return {
      profiseeUrl,
      saveProfiseeUrl,
      isTrialExperiencedEnabled,
    };
  }, [profiseeUrl, saveProfiseeUrl, isTrialExperiencedEnabled]);

  return (
    <ConnectionContext.Provider value={value}>
      {children}
    </ConnectionContext.Provider>
  );
};

export const useConnectionContext = () => {
  const context = useContext(ConnectionContext);

  return context;
};
