export const authErrorKey = "authError";
export const profiseeUrlKey = "profiseeUrl";
export const isAuthLoadingKey = "isAuthLoading";
export const shouldDispatchKey = "shouldDispatch";

export const getStorageProfiseeUrl = () => {
  return localStorage.getItem(profiseeUrlKey);
};

export const setStorageProfiseeUrl = (url: string) => {
  return localStorage.setItem(profiseeUrlKey, url);
};

export const removeStorageProfiseeUrl = () => {
	return localStorage.removeItem(profiseeUrlKey);
  };

export const setStorageError = (error: Error) => {
  let stringifiedError: string;
  try {
    stringifiedError = JSON.stringify(error);
  } catch (error) {
    stringifiedError = "";
  }

  localStorage.setItem(authErrorKey, stringifiedError);
};

export const getStorageIsAuthLoading = () => {
  return JSON.parse(localStorage.getItem(isAuthLoadingKey));
};

export const setStorageIsAuthLoading = (isLoading: boolean, shouldDispatch = true) => {
  localStorage.setItem(shouldDispatchKey, JSON.stringify(shouldDispatch));
  return localStorage.setItem(isAuthLoadingKey, JSON.stringify(isLoading));
};

export const setDispatchIsAuthLoading = (isLoading: boolean) => {
  localStorage.setItem(shouldDispatchKey, JSON.stringify(false));
  return localStorage.setItem(isAuthLoadingKey, JSON.stringify(isLoading));
};

const setStorageShouldDispatch = (shouldDispatch: boolean) => {
  return localStorage.setItem(shouldDispatchKey, JSON.stringify(shouldDispatch));
};

export const getStorageShouldDispatch = () => {
  try {
    return JSON.parse(localStorage.getItem(shouldDispatchKey));
  } catch (error) {
    return false;
  }
};

export const resetStorage = () => {
  setStorageError(null);
  setStorageShouldDispatch(false);
  setStorageIsAuthLoading(false);
  removeStorageProfiseeUrl();
};
