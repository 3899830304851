export const trialClientIdStorageKey = process.env.TRIAL_STORAGE_KEY ?? "";
export const fallbackClientId = process.env.FALLBACK_CLIENT_ID ?? "";
export const defaultGetClientId = process.env.GET_DATA_PRODUCT_CLIENT_ID ?? "";
export const trialExperienceUrl = process.env.TRIAL_URL ?? "";

export const initTrialVersion = () => {
  const clientId = localStorage.getItem(trialClientIdStorageKey);
  if (!clientId) {
    localStorage.setItem(trialClientIdStorageKey, fallbackClientId);
  }
};

const normalizedUrl = trialExperienceUrl
  // removes trailing slash, if any
  .replace(/\/+$/, "")
  // escapes special characters for regex
  .replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

const regex = new RegExp(`^${normalizedUrl.replace(/\/+$/, "")}(/+)?$`, "i");

export const isTrialUrl = (url: string) => {
  return regex.test(url);
};
