import { useMemo } from "react";
import { useConnectionContext } from "common/providers/ConnectionProvider";
import { UserManagerSettings, WebStorageStateStore } from "oidc-client-ts";

export const profiseeAuthRedirectPath = "/profisee-close";
export const profiseeSilentAuthRedirectPath = "/profisee-silent";

export const staticAuthSettings: UserManagerSettings = {
  authority: "",
  client_id: "Fabric.Implicit",
  response_type: "code",
  scope: "ProfiseeAPI openid profile offline_access",
  redirect_uri: window.location.origin + profiseeAuthRedirectPath,
  silent_redirect_uri: window.location.origin + profiseeSilentAuthRedirectPath,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
};

export const createAuthURL = (url: string) => {
  let authorityUrl: string;

  try {
    // Ensure url is defined and avoid multiple slashes
    authorityUrl = new URL("auth", url).href;
  } catch {
    authorityUrl = "";
  }
  return authorityUrl;
};

export const useAuthSettings = () => {
  const { profiseeUrl, isTrialExperiencedEnabled } = useConnectionContext();

  const settings = useMemo(() => {
    return { ...staticAuthSettings, authority: createAuthURL(profiseeUrl) };
  }, [profiseeUrl]);

  return {settings, isTrialExperiencedEnabled};
};
